.header{
    position: fixed;
    top: 0;
    z-index: 10;
    background: $green-exlight;
    width: 100%;
    @include sp{
        height: 60px;
    }
    &_contents{
        padding: 10px;
        @include pc{
            padding: 22px 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            #hamburger {
                display: none;
            }
        }
        .header_logo{
            a{
                display: inline-block;
            }
            img{
                max-width: 215px;
                min-width: 100px;
                @include sp{
                    height: 40px;
                }
            }
        }
        .gNav_list{
            font-weight: 500;
            white-space: nowrap;
            @include pc{
                display: flex;
                :nth-of-type(-n+5) {
                    &::after{
                        position: absolute;
                        left: 0;
                        content: '';
                        width: 100%;
                        height: 2px;
                        background: $green;
                        bottom: -1px;
                        transform: scale(0, 1);
                        transform-origin: center top;
                        transition: .3s;
                        display: inline;
                    }
                    :hover::after{
                        transform: scale(1, 1);
                    }
                }
            }
            @include sp{
                text-align: center;
            }
            .gNav_item{
                margin: 0 20px;
                position: relative;
                @include sp{
                    margin: 0 24px 10px;
                    a{
                        padding: 20px 0;
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
    @include sp{
        #nav{
            .gNav{
                position: fixed;
                transition: .7s;
                height: 100%;
                width: 100%;
                left: -100%;
                top: 60px;
                background-color: #fff;
                z-index: 5;
                overflow-y: auto;
                &_list{
                    padding-bottom: 80px;
                }
            }
        }
        #hamburger {
        position: absolute;
        top: 19px;
        right: 20px;
        width: 30px;
        height: 22px;
        transition: .5s;
        cursor: pointer;
        z-index: 8;
        }
        .inner_line {
        display: block;
        position: absolute;
        left: 0;
        width: 30px;
        height: 2px;
        background-color: $green;
        transition: .5s;
        }
        #line2 {
            top: 0;
        }
        #line2 {
            top: 10px;
        }
        #line3 {
            bottom: 0px;
        }
        .in{
        transform: translateX(100%);
        }
        .line_1,.line_2,.line_3{
            background-color: $green;
        }
        .line_1 {
        transform: translateY(10px) rotate(-315deg);
        }
        .line_2 {
        opacity: 0;
        }
        .line_3 {
        transform: translateY(-10px) rotate(315deg);
        }
    }
}