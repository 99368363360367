// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
headLine3

見出し3

Markup:
<h3 class="headLine3">見出し3</h3>

Styleguide #{$guideNum}
*/



.sec_item-sub {
	@include gBack($fontWeight:500);
	display: inline-block;
	padding: 0 14px;
	font-size: rem(14px, pc);
}
.sec_item-main {
	font-size: rem(30px, pc);
	font-weight: 700;
	@include sp{
		font-size: rem(24px, pc);
	}
}

