// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

.editorTmplGrid {
	$this: &;
	margin: 20px 0 0;
	&_list {
		margin: -20px 0 0 -20px;
		display: flex;
		display: -webkit-flex;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		flex-direction: row;
	}
	&_col {
		margin: 20px 0 0 20px;
		& > :first-child {
			margin-top: 0;
		}
		@for $i from 1 through 13 {
			&-#{$i}-12 { width: calc((99.9%/12 * #{$i}) - 20px); }
		}
	}

	&-center {
		#{$this}_list {
			justify-content: center;
		}
	}
	&-right {
		#{$this}_list {
			justify-content: flex-end;
		}
	}
}

@media screen and (min-width: $breakpoint-sp + 1){
	.editorTmplGrid {
		$this: &;
		&_list {
		}
		&_col {
			@for $i from 1 through 13 {
				&-#{$i}-12pc { width: calc((99.9%/12 * #{$i}) - 20px); }
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.editorTmplGrid {
		$this: &;
		&_list {
			margin: -20px 0 0 -20px;
		}
		&_col {
			margin: 20px 0 0 20px;
			@for $i from 1 through 13 {
				&-#{$i}-12 { width: calc((99.9%/12 * #{$i}) - 20px); }
			}
			@for $i from 1 through 13 {
				&-#{$i}-12sp { width: calc((99.9%/12 * #{$i}) - 20px); }
			}
		}
	}
}