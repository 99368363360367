// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
dataThTable

レスポンシブでdata-thが出るテーブル

Markup:
<table class="dataThTable">
	<thead>
		<tr>
			<th>見出し1</th>
			<th>見出し2</th>
			<th>見出し3</th>
			<th>見出し4</th>
			<th>見出し5</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td data-th="見出し1">内容1</td>
			<td data-th="見出し2">内容2</td>
			<td data-th="見出し3">内容3</td>
			<td data-th="見出し4">内容4</td>
			<td data-th="見出し5">内容5</td>
		</tr>
	</tbody>
</table>

Styleguide #{$guideNum}
*/
@media screen and (max-width: $breakpoint-sp){
	.dataThTable {
		display: block;
		border: 1px solid $gray-border;
		padding: 10px;
		& > thead {
			display: none;
		}
		& > tbody {
			display: block;
			border: none;
			padding: 0;
			& > :first-child {
				padding-top: 0;
				border-top: none;
			}
			& > tr {
				display: block;
				border: none;
				border-top: 1px solid $gray-border;
				padding: 10px 0;
				& > th,
				& > td {
					display: block;
					border: none;
					padding: 0;
				}
				& > [data-th] {
					display: flex;
					flex-wrap: wrap;
					align-items: flex-start;
					justify-content: flex-start;
					&:before {
						content: attr(data-th)'：';
						font-weight: bold;
					}
				}
			}
		}
	}
}
