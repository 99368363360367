// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
btn

ボタン

Markup:
<div class="btns btns-hr">
	<ul class="btns_list">
		<li class="btns_item btns_item-4-12"><a href="" class="btn {$modifiers}">ボタン</a></li>
		<li class="btns_item btns_item-4-12"><button class="btn {$modifiers}">ボタン</button></li>
	</ul>
</div><!-- /.btns -->

.btn-red - $red
.btn-redLight - $redLight
.btn-blue - $blue
.btn-blueLight - $blueLight
.btn-gray - $gray
.btn-grayLight - $grayLight
.btn-gray-border - $gray-border

Styleguide #{$guideNum}
*/
.btn {
	$this: &;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 10px;
	background: $gray-light;
	text-decoration: none;
	cursor: pointer;
	color: inherit;
	border: 1px solid transparent;
	transition: all .4s;

	&[disabled] {
		opacity: .6 !important;
		cursor: default !important;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.btn {
		$this: &;
	}
}


//ボタン共通
@mixin btn {
	text-decoration: none;
	cursor: pointer;
	transition: all .4s;
}

//ヘッダーbtn
.btn01 {
	$this: &;
	width: 100%;
	padding: 10px 42px;
	background: $green;
	color: #fff;
	filter: drop-shadow(0 0 6px #7F9B92);
	@include btn;
	@include gBack($fontWeight:500);
	
	&[disabled] {
		opacity: .6 !important;
		cursor: default !important;
	}
}

//無料相談btn
@mixin contctBtn{
	text-align: center;
	display: block;
	position: relative;
	padding: 14px 0;
	font-size: rem(20px, pc);
	filter: drop-shadow(0 0 6px #89A79E);
	@include btn;
	&[disabled] {
		opacity: .6 !important;
		cursor: default !important;
	}
	&::after{
		content: "";
		position: absolute;
		top: 50%;
		right: 25px;
		transform: translateY(-50%);
		display: block;
		width: 14px;
		height: 16px;
		background: url(/assets/images/top/btn_arrow01.png) no-repeat center / contain;
	}
	@include pc{
		&:hover{
			transform: translateY(-5px);
		}
	}
	@include sp{
		font-size: rem(16px, pc);
		width: 100%;
		padding: 14px ;
	}
}

//緑
.btn02 {
	@include contctBtn;
	@include gBack;
	width: 100%
}
//白
.btn03{
	@include contctBtn;
	@include gBack($backColor:#fff, $fontColor:$green);
	width: 450px;
	filter: drop-shadow(0 0 10px #43544F);
	&::after{
		background: url(/assets/images/top/btn_arrow02.png) no-repeat center / contain;
	}
	
}




