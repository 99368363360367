// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
scrollTable

レスポンシブでスクロールになるテーブル

Markup:
<div class="scrollTable">
	<table>
		<thead>
			<tr>
				<th>見出し</th>
				<th>見出し</th>
				<th>見出し</th>
				<th>見出し</th>
				<th>見出し</th>
				<th>見出し</th>
				<th>見出し</th>
				<th>見出し</th>
				<th>見出し</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<th>見出し</th>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
			</tr>
			<tr>
				<th>見出し</th>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
			</tr>
			<tr>
				<th>見出し</th>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
			</tr>
		</tbody>
	</table>
</div><!-- /.scrollTable -->

Styleguide #{$guideNum}
*/
.scrollTable {
	$this: &;
	margin: 20px 0 0;
	& > :first-child {
		margin-top: 0;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.scrollTable {
		$this: &;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		background: linear-gradient(to left, rgba(255, 255, 255, 0), white 15px) 0 0/50px 100%, linear-gradient(to left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.15)) 0 0/30px 100%, linear-gradient(to right, rgba(255, 255, 255, 0), white 15px) right/50px 100%, linear-gradient(to right, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.15)) right/30px 100%;
		background-repeat: no-repeat;
		background-attachment: local, scroll, local, scroll;
		& > table {
			min-width: 100%;
			width: auto;
			white-space: nowrap;
		}
	}
}