// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
headLine1

見出し1

Markup:
<h1 class="headLine1">見出し1</h1>

Styleguide #{$guideNum}
*/
.headLine1 {
	$this: &;
}

@media screen and (max-width: $breakpoint-sp){
	.headLine1 {
		$this: &;
	}
}
