// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
headLine4

見出し4

Markup:
<h4 class="headLine4">見出し4</h4>

Styleguide #{$guideNum}
*/


h4.sec_item-main {
	font-size: rem(20px, pc);
	font-weight: 700;
}
