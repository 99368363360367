// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
headLine2

見出し2

Markup:
<h2 class="sec_ttl">
	<span class="sec_ttl-sub">AAAK</span>
	<p class="sec_ttl-main">タイトル</p>
</h2>

Styleguide #{$guideNum}
*/
.headLine2 {
	$this: &;
}

@media screen and (max-width: $breakpoint-sp){
	.headLine2 {
		$this: &;
	}
}


.sec_ttl {
	text-align: center;
	&-sub {
		font-size: rem(22px, pc);
		@include sp{
			font-size: rem(16px, sp);
		}
		@include gBold;
		letter-spacing: 0.12em;
	}
	&-main {
		font-size: rem(40px, pc);
		@include sp{
			font-size: rem(24px, sp);
		}
		font-weight: 700;
	}
}


