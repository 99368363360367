@font-face {
	font-family: "icons";
	src: url('/assets/fonts/iconfont/icons.eot');
	src: url('/assets/fonts/iconfont/icons.eot?#iefix') format('eot'),
		url('/assets/fonts/iconfont/icons.woff') format('woff'),
		url('/assets/fonts/iconfont/icons.ttf') format('truetype'),
		url('/assets/fonts/iconfont/icons.svg#icons') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

//  font
//----------------------------------------------------------------------
@mixin icons($code) {
	content: $code;
	font-family: "icons";
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


	$icons-caret-down: "\EA01";
	$icons-caret-left: "\EA02";
	$icons-caret-right: "\EA03";
	$icons-caret-up: "\EA04";
	$icons-check: "\EA05";
	$icons-circleArrow-down: "\EA06";
	$icons-circleArrow-left: "\EA07";
	$icons-circleArrow-right: "\EA08";
	$icons-circleArrow-up: "\EA09";
	$icons-close: "\EA0A";



