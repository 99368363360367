//  breakpoints
//----------------------------------------------------------------------
$breakpoint-sp: 736px;
$breakpoint-sp-md: 737px;

$breakpoint-md: 1370px;

//  size
//----------------------------------------------------------------------
$page-width: 1160px; //仮
$main-width: 960px; //仮
$side-width: 210px; //仮

$font-size: (
	'pc': 16px, //仮
	'sp': 14px, //仮
) !default;
$lineHeight: calc(30/16);

//  colors
//----------------------------------------------------------------------

$txtColor: #2C3632;

$overlayBg: rgba(#000, .8); //仮

$green:#386F5E;
$green-light:#DCEBE4;
$green-exlight:#E9F2EE;


$overlayBg: rgba(#000, .8); //仮

$red: #f00; //仮
$red-light: lighten($red, 10%); //仮

$blue: #00f; //仮
$blue-light: lighten($blue, 10%); //仮

$gray: #888; //仮
$gray-light: lighten($gray, 10%); //仮
$gray-exLight: lighten($gray, 43%); //仮
$gray-border: $gray-light; //仮
$gray-bg: $gray-exLight; //仮

$black: #222; //仮


//  font
//----------------------------------------------------------------------
$fa-font-path: "/assets/fonts/font-awesome";
$fa-font-size-base: map-get($font-size, 'pc');
$fa-fw-width: calc(20em / 16);

@mixin far($code, $fw: true) {
	content: $code;
	font-family: 'Font Awesome 5 Free';
	font-weight: 400;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	@if $fw {
		text-align: center;
		width: $fa-fw-width;
	}
}
@mixin fa($code, $fw: true) {
	content: $code;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	@if $fw {
		text-align: center;
		width: $fa-fw-width;
	}
}
@mixin fas($code, $fw: true) {
	content: $code;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	@if $fw {
		text-align: center;
		width: $fa-fw-width;
	}
}
@mixin fab($code, $fw: true) {
	content: $code;
	font-family: 'Font Awesome 5 Brands';
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	@if $fw {
		text-align: center;
		width: $fa-fw-width;
	}
}

@mixin grade($from, $to, $vertical: false) {
	@if $vertical {
		background: $from;
		background: -moz-linear-gradient(top, #{$from} 0%, #{$to} 100%);
		background: -webkit-linear-gradient(top, #{$from} 0%,#{$to} 100%);
		background: linear-gradient(to bottom, #{$from} 0%,#{$to} 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$from}', endColorstr='#{$to}',GradientType=0 );
	} @else {
		background: $from;
		background: -moz-linear-gradient(left, #{$from} 0%, #{$to} 100%);
		background: -webkit-linear-gradient(left, #{$from} 0%,#{$to} 100%);
		background: linear-gradient(to right, #{$from} 0%,#{$to} 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$from}', endColorstr='#{$to}',GradientType=1 );
	}
}

//px to rem
@function rem($px, $key: 'pc') {
	$value: map-get($font-size, $key);
	@return calc(($px / $value) * 1rem);
}


//文字　初期が緑で太字
@mixin gBold($fontColor:$green) {
	color: $fontColor;
	font-weight: 700;
}

//文字白で太字、背景緑、角全丸
@mixin gBack($fontWeight:700, $backColor:$green, $fontColor:#fff) {
	color: $fontColor;
	font-weight: $fontWeight;
	background-color: $backColor;
	border-radius: 50vh;
}

//背景うす緑、影あり、角すこし丸
@mixin gShadow($backColor:$green-light, $filterColor:drop-shadow(0 0 20px #D6DFDD)) {
	filter: $filterColor;
	border-radius: 5px;
	background-color: $backColor;
}

// 背景装飾
@mixin bDeco {
	content: '';
	display: inline-block;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	position: absolute;
	z-index: 2;
}


// sp時のみ表示
@mixin sp {
	@media screen and (max-width: $breakpoint-sp), print{
		@content;
	}
}
// ノパソ用サイズ変更
@mixin md {
	@media screen and (min-width: $breakpoint-sp-md) and (max-width: $breakpoint-md), print{
		@content;
	}
}
// pc時のみ表示
@mixin pc {
	@media screen and (min-width: $breakpoint-sp-md), print{
		@content;
	}
}

.hidden-pc{
	@include pc{
		display: none;
	}
}
.hidden-sp{
	@include sp{
		display: none;
	}
}