// @import './_config';
// @import './_iconFonts';
// @import './_svgSprite';
// @import './_imgSprite';

/*!
 * Bootstrap Reboot v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */


*,
*::before,
*::after {
	box-sizing: border-box;
}

* {margin: 0px;padding: 0px; outline: none;}

html {
	font-family: sans-serif;
	font-size: map-get($font-size, 'pc');
	line-height: $lineHeight;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	height: 100%;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
	display: block;
}

body {
	height: 100%;
	margin: 0;
	font-family: 'Zen Kaku Gothic New', sans-serif;
	font-weight: 400;
	line-height: $lineHeight;
	color: $txtColor;
	font-size: 1rem;
	word-break: break-all;
	text-align: left;
	background-color: $green-exlight;
}

[tabindex="-1"]:focus {
	outline: 0 !important;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: normal;
}

p {
	padding: 0px; margin-bottom: 0;
}

abbr[title],
abbr[data-original-title] {
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none;
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

ol,
ul,
dl {
	padding: 0; margin: 0; 
}
ul {
	padding: 0;
	& > li {
		display: block;
	}
}

ol,
ul:not([class]),
ul.dotList {
	padding-left: 2rem;
	& > li {
		display: list-item;
	}
	ol,
	ul:not([class]),
	ul.dotList {
		margin-top: 0;
	}
}

dt {
	font-weight: normal;
}

dd {
	margin-left: 0;
}

blockquote {
	margin: 20px 0 0;
}

b,
strong {
	font-weight: bold;
}

small {
	font-size: .8em;
}

sub,
sup {
	position: relative;
	font-size: .75em;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -.25em;
}

sup {
	top: -.5em;
}

a {
	background-color: transparent;
	outline: 0;
	text-decoration: none;
	color: $txtColor;
}

pre,
code,
kbd,
samp {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	font-size: 1em;
}

pre {
	margin-top: 20px;
	margin-bottom: 0;
	overflow: auto;
}

img {
	vertical-align: middle;
	border-style: none;
	backface-visibility: hidden;
	max-width: 100%;
}

svg {
	overflow: hidden;
	vertical-align: middle;
}

iframe {
	max-width: 100%;
	border: none;
}

table {
	margin: 20px 0 0;
	border-collapse: collapse;
	width: 100%;
}

caption {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #6c757d;
	text-align: left;
	caption-side: bottom;
}

th,
td {
	text-align: left;
	border: 1px solid $gray-border;
	background-clip: padding-box;
	padding: 10px;
	& > :first-child {
		margin-top: 0;
	}
}

th {
	text-align: inherit;
}
thead {
	th {
		text-align: center;
	}
}

label {
	display: inline-block;
	margin: 0;
}

button {
	border-radius: 0;
}

button:focus {
	outline: none;
}

input,
button,
select,
optgroup,
textarea {
	padding: 0;
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	outline: none;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}


[type="text"],
[type="search"],
[type="tel"],
[type="url"],
[type="email"],
[type="password"],
[type="datetime"],
[type="date"],
[type="month"],
[type="week"],
[type="time"],
[type="datetime-local"],
[type="number"],
select,
textarea {
	width: 100%;
	min-width: 1px;
	font-family: inherit;
	border: 1px solid $gray-border;
	height: 40px;
	padding: 0 10px;
	transition: all .4s;
	&:disabled {
		background: $gray-bg;
	}
}

select {
	word-wrap: normal;
	padding-right: 0;
}

textarea {
	padding: 10px;
	overflow: auto;
	resize: vertical;
	height: 150px;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: none;
	background: none;
	border: none;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
	cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
	box-sizing: border-box;
	padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
	-webkit-appearance: listbox;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: .5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
}

progress {
	vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
	margin: 0;
	-webkit-appearance: none;
}
[type="number"] {
	-moz-appearance:textfield;
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

output {
	display: inline-block;
}

summary {
	display: list-item;
	cursor: pointer;
}

template {
	display: none;
}

.fade {
	&-enter-active {
		display: block;
		transition: opacity .4s;
	}
	&-enter {
		opacity: 0;
	}
	&-enter-to {
		opacity: 1;
	}

	&-leave-active {
		display: block;
		transition: opacity .4s;
	}
	&-leave {
		opacity: 1;
	}
	&-leave-to {
		opacity: 0;
	}
}
[v-cloak] {
	display: none !important;
}

@media screen and (min-width: $breakpoint-sp){
	a,
	button {
		transition: opacity .4s;
		&:hover {
			opacity: .6;
			text-decoration: none;
		}
	}
}


@media screen and (max-width: $breakpoint-sp){
	html {
		font-size: map-get($font-size, 'sp');
	}
	a,
	button {
	}
}