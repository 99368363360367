// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

.editorTmplBnrs {
	$this: &;
	margin: 20px 0 0;
	&_list {
		display: flex;
		display: -webkit-flex;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		flex-direction: row;
		margin: -20px 0 0 -20px;
	}
	&_item {
		margin: 20px 0 0 20px;
		text-align: center;
		& > a {
			display: inline-block;
			vertical-align: middle;
			letter-spacing: normal;
		}
		@for $i from 1 through 13 {
			&-#{$i}-12 { width: calc((99.9%/12 * #{$i}) - 20px);}
		}
	}
}

@media screen and (min-width: $breakpoint-sp + 1){
	.editorTmplBnrs {
		$this: &;
		&_list {
		}
		&_item {
			@for $i from 1 through 13 {
				&-#{$i}-12pc { width: calc((99.9%/12 * #{$i}) - 20px);}
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.editorTmplBnrs {
		$this: &;
		&_list {
			margin: -10px 0 0 -10px;
		}
		&_item {
			margin: 10px 0 0 10px;
			@for $i from 1 through 13 {
				&-#{$i}-12 { width: calc((99.9%/12 * #{$i}) - 10px);}
			}
			@for $i from 1 through 13 {
				&-#{$i}-12sp { width: calc((99.9%/12 * #{$i}) - 10px);}
			}
		}
	}
}