// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


[data-acc] {}
[data-acc-btn] {}
[data-acc-content] {
	overflow: hidden;
	visibility: hidden;
	transition: max-height .4s;
}
