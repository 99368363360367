// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
responsiveTable

スマホでブロック要素になるテーブル

Markup:
<table class="responsiveTable">
	<tbody>
		<tr>
			<th>あああああああああ</th>
			<td>いいいいいいいいいいいいいいいいいいいいいいいいいいい</td>
		</tr>
		<tr>
			<th>あああああああああ</th>
			<td>いいいいいいいいいいいいいいいいいいいいいいいいいいい</td>
		</tr>
		<tr>
			<th>あああああああああ</th>
			<td>いいいいいいいいいいいいいいいいいいいいいいいいいいい</td>
		</tr>
		<tr>
			<th>あああああああああ</th>
			<td>いいいいいいいいいいいいいいいいいいいいいいいいいいい</td>
		</tr>
	</tbody>
</table>

Styleguide #{$guideNum}
*/
@media screen and (max-width: $breakpoint-sp){
	.responsiveTable {
		display: block;
		& > tbody {
			display: block;
			border: none;
			padding: 0;
			& > tr {
				display: block;
				border: none;
				padding: 0;
				& > th,
				& > td {
					display: block;
					border: none;
					padding: 0;
				}
				& > th {
				}
				& > td {
				}
				& > :first-child {
					margin-top: 0;
				}
			}
			& > :first-child {
				margin-top: 0;
			}
		}
	}
}
