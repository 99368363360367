@import '../_config';
@import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

.mv{
    &_area{
        position: relative;
        padding-top: 190px;
        padding-bottom: 80px;
        margin: auto;
        min-height: 850px;
        @include md{
            padding-top: 200px;
        }
        @include sp{
            padding-top: 100px;
            min-height: auto;
        }
        &::before{
            content: "";
            width: 100%;
            height: 100%;
            background-color: #ADD1C6;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -2;
        }
        &::after{
            content: "";
            width: 100%;
            height: 75%;
            background-color: $green-exlight;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            border-radius: 0 0 0 180px;
            @include md{
                height: 73%;
            }
            @include sp{
                height: 70%;
                border-radius: 0 0 0 40px;
            }
        }
    }
    &_inner{
        display: flex;
        justify-content: space-between;
        width: min(100%, 1920px);
        margin: auto;
        @include sp{
            flex-flow: column nowrap;
        }
    }
    &_ttl{
        position: relative;
        margin-left: 12%;
        @include md{
            margin-left: 11%;
            margin-right: 10px;
        }
        @include sp{
            margin: auto;
        }
        &-main{
            font-size: rem(52px, pc);
            @include md{
                font-size: rem(42px, pc);
            }
            @include sp{
                font-size: rem(28px, sp);
            }
            line-height: 1.4;
            white-space: nowrap;
            @include gBold;
        }
        &-sub{
            @include gBold($fontColor:$txtColor);
            @include pc{
                font-size: rem(24px, pc);
                margin-top: 10px;
            }
            @include md{
                font-size: rem(20px, pc);
            }
            span{
                font-size: rem(32px, pc);
                margin-left: 5px;
                @include md{
                    font-size: rem(25px, pc);
                }
                @include sp{
                    font-size: rem(22px, pc);
                }
            }
        }
        &-btn{
            margin-top: 30px;
            @include sp{
                margin-top: 40px;
            }
            .btn_txt{
                position: relative;
                text-align: center;
                margin-bottom: 10px;
                @include md{
                    width: 370px;
                }
                span{
                    display: inline-block;
                    &::before{
                        position: absolute;
                        top: 50%;
                        left:  calc(50% - 17em / 2 - 0.5em);
                        width: 1px;
                        height: 70%;
                        background-color: $txtColor;
                        content: "";
                        transform: rotate(-30deg) translateY(-50%);
                    }
                    &::after{
                        position: absolute;
                        top: 50%;
                        right: calc(50% - 17em / 2 - 0.5em);
                        width: 1px;
                        height: 70%;
                        background-color: $txtColor;
                        content: "";
                        transform: rotate(30deg) translateY(-50%);
                    }
                }
            }
        }
        @include pc{
            &::before{
                @include bDeco;
                width: 115px;
                height: 124px;
                background-image: url(/assets/images/top/main_bg01.png);
                left: -20%;
                bottom: 90px;
                @include md{
                    left: -25%;
                }
            }
            &::after{
                @include bDeco;
                width: 116px;
                height: 170px;
                background-image: url(/assets/images/top/main_bg02.png);
                right: -40%;
                top: -90px;
                @include md{
                    right: -30%;
                }
            }
        }
    }
    &_img{
        margin-right: 40px;
        @include sp{
            margin: 60px 10px 0;
        }
        img{
            @include pc{
                width: 1099px;
                min-width: 900px;
            }
        }
    }
}


//お悩み
.task{
    background-color: #ADD1C6;
    text-align: center;
    position: relative;
    padding-bottom: 160px;
    @include sp{
        padding-bottom: 70px;
    }
    &_inner{
        position: relative;
        @include pc{
            &::after{
                @include bDeco;
                width: 126px;
                height: 162px;
                background-image: url(/assets/images/top/sec01_bg.png);
                right: -300px;
                top: 200px;
            }
        }
        @include sp{
            margin: 0 20px;
        }
    }
    .sec_ttl{
        margin-bottom: 120px;
        @include sp{
            margin-bottom: 60px;
        }
    }
    .case{
        margin-bottom: 40px;
        &_list{
            display: flex;
            gap: 50px;
            @include sp{
                flex-flow: column nowrap;
                justify-content: center;
            }
        }
        &_item{
            width: calc((100% - 50px * 2) / 3);
            @include sp{
                width: 100%;
            }
            padding-bottom: 30px;
            @include gShadow($filterColor:drop-shadow(0 0 20px #96B4AB));
            .case_img{
                margin: -80px 0 32px;
                @include sp{
                    margin: -30px 0 20px;
                }
                img{
                    height: 258px;
                    object-fit: contain;
                    @include sp{
                        padding: 0 90px;
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .sec_item-sub{
                margin-bottom: 15px;
            }
        }
    }
    .arrow{
        margin-bottom: 30px;
        img{
            height: 80px;
            @include sp{
                height: 50px;
            }
        }
    }
    &_lead{
        font-size: rem(36px, pc);
        font-weight: 700;
        position: relative;
        @include sp{
            font-size: rem(24px, pc);
        }
        span{
            color: $green;
            border-bottom: 4px solid $green;
        }
        @include pc{
        &::before{
                @include bDeco;
                width: 200px;
                height: 321px;
                background-image: url(/assets/images/top/task01.png);
                left: 0;
                top: -80px;
            }
            &::after{
                    @include bDeco;
                    width: 200px;
                    height: 321px;
                    background-image: url(/assets/images/top/task02.png);
                    right: -20px;
                    top: 0;
                }
        }
    }
    // 背景の下三角形
    .area_arrow{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        div[class^="arrow"]{
            position: absolute;
            bottom: 0;
            width: 0;
            height: 0;
            border-style: solid;
        }
        .arrow-left{
            right: 50%;
            border-width: 300px 0 0 1920px;
            border-color: transparent transparent transparent $green-exlight;
        }
        .arrow-right{
            left: 50%;
            border-width: 0 0 300px 1920px;
            border-color: transparent transparent $green-exlight transparent;
        }
    }
}


//解決
.solve{
    padding: 100px 0 40px;
    @include sp{
        padding: 40px 0 20px;
    }
    &_inner{
        position: relative;
        @include pc{
            &::after{
                @include bDeco;
                width: 152px;
                height: 129px;
                background-image: url(/assets/images/top/sec02_bg.png);
                left: -260px;
                bottom: -130px;
            }
        }
        @include sp{
            margin: 0 20px;
        }
    }
    &_body{
        @include pc{
            display: flex;
        }
    }
    &_ttl{
        font-size: rem(46px, pc);
        font-weight: 700;
        text-align: center;
        margin-bottom: 80px;
        @include sp{
            font-size: rem(28px, pc);
            margin-bottom: 40px;
        }
        span{
            border-bottom: 6px solid #fff;
            span{
                color: $green;
                font-size: rem(54px, pc);
                @include sp{
                    font-size: rem(28px, pc);
                }
                border: none;
            }
        }
    }
    &_txt{
        @include pc{
            flex: 4.5;
            margin-right: 60px;
        }
        h3{
            @include gBold;
            font-size: rem(30px, pc);
            margin-bottom: 42px;
            @include sp{
                font-size: rem(22px, pc);
                margin-bottom: 22px;
            }
        }
        p{
            padding-bottom: 20px;
        }
    }
    &_img{
        margin-top: 10px;
        @include pc{
            flex: 5.5;
            margin: 0 -60px 0 0;
            img{
                @include img-vw(400,768);
            }
        }
    }
}


// 理由
.reason{
    .sec_ttl{
        margin-bottom: 60px;
        @include sp{
            margin-bottom: 24px;
        }
    }
    .sec_item-sub{
        margin-bottom: 15px;
        @include sp{
            margin-bottom: 10px;
        }
    }
    .sec_item-main{
        margin-bottom: 24px;
        @include sp{
            margin-bottom: 18px;
        }
    }
    .point{
        position: relative;
        @include pc{
            display: flex;
        }
        &::before{
            content: '';
            display: inline-block;
            width: 100vw;
            position: absolute;
            z-index: -1;
            top: 30px;
            background-color: $green-light;
            @include sp{
                top: 50px;
                border-radius: 0;
            }
        }
    }
    .point:nth-child(even) {
        &::before{
            height: 110%;
            left: 100px;
            border-radius: 15px 0 0 15px;
            @include sp{
                left: 0;
                border-radius: 0;
                height: 100%;
            }
        }
    }
    .point:nth-child(odd) {
        flex-direction: row-reverse;
        &::before{
            height: 120%;
            right: 100px;
            border-radius: 0 15px 15px 0;
            @include sp{
                left: 0;
                border-radius: 0;
                height: 100%;
            }
        }
    }
    .point01{
        margin-bottom: 120px;
        position: relative;
        @include sp{
            margin-bottom: 80px;
        }
        &_img{
            margin-left: -60px;
            flex: 3;
            @include sp{
                margin: auto;
                padding:  0 30px;
            }
        }
        &_txt{
            margin: 90px 0 0 90px;
            flex: 2;
            @include sp{
                margin: 20px;
            }
            position: relative;
            &::after{
                @include bDeco;
                width: 133px;
                height: 190px;
                background-image: url(/assets/images/top/point01-2.png);
                right: 0;
                bottom: -60px;
                @include sp{
                    width: 80px;
                    top: -130px;
                }
            }
        }
        @include pc{
            &::after{
                @include bDeco;
                width: 137px;
                height: 71px;
                background-image: url(/assets/images/top/sec03_bg.png);
                right: -300px;
                top: 10px;
            }
        }
    }
    .point02{
        margin-bottom: 80px;
        &_img{
            margin-right: -60px;
            flex: 3;
            @include sp{
                margin: auto;
                padding:  0 30px;
            }
        }
        &_txt{
            margin: 90px 60px 0 0;
            flex: 2;
            position: relative;
            @include sp{
                margin: 20px;
            }
            &::after{
                @include bDeco;
                width: 182px;
                height: 175px;
                background-image: url(/assets/images/top/point02-2.png);
                left: 0;
                bottom: -110px;
                @include sp{
                    width: 95px;
                    top: -110px;
                    right: 0;
                    left: auto;
                    transform: scale(-1, 1);
                }
            }
        }
    }
}


// お問い合わせ_1

//お問い合わせ共通
.contact {
    color: #fff;
    &_inner >div{
        padding: 0 20px;
    }
    &_txt{
        padding: 100px 0;
        @include sp{
            padding: 60px 0 40px;
        }
        p{
            margin-bottom: 48px;
            @include sp{
                margin-bottom: 28px;
            }
        }
    }
}
.contact01{
    position: relative;
    z-index: 1;
    &_inner{
        >div{
            @include sp{
                padding-bottom: 40px;
            }
            @include pc{
                display: flex;
                margin-left: -200px;
                margin-right: -200px;
                padding: 0 200px;
                border-radius: 15px;
            }
            background-color: $green;
        }
    }
    &_ttl{
        @include gBold($fontColor:#fff);
        font-size: rem(40px, pc);
        margin-bottom: 24px;
        @include sp{
            font-size: rem(24px, sp);
        }
    }
    &_txt{
        flex-basis: 55%;
    }
    &_img{
        flex-basis: 46%;
        padding-top: 35px;
        @include sp{
            padding-top: 0;
        }
    }
}


// 機能
.function{
    background-color: #F7FCF9;
    @include pc{
        margin-top: -150px;
        padding-top: 290px;
    }
    &_inner{
        position: relative;
        @include sp{
            margin: 0 20px;
        }
        @include pc{
            &::before{
                @include bDeco;
                width: 129px;
                height: 138px;
                background-image: url(/assets/images/top/sec05_bg1.png);
                left: -300px;
                top: 200px;
            }
            &::after{
                @include bDeco;
                width: 129px;
                height: 138px;
                background-image: url(/assets/images/top/sec05_bg2.png);
                right: -300px;
                bottom: 200px;
            }
        }
    }
    .sec_ttl{
        margin-bottom: 60px;
        @include sp{
            margin-bottom: 24px;
        }
    }
    &_list{
        display: grid;
        gap: 24px;
        @include pc{
            grid-template-columns:repeat(3, 1fr);
            gap: 50px;
        }
    }
    &_item{
        padding: 40px 38px;
        @include sp{
            padding: 30px;
        }
        @include gShadow($backColor:#fff);
        .function_ttl{
            text-align: center;
            &-sub{
                color: $green;
                font-size: rem(14px, pc);
                font-weight: 900;
            }
        }
        .function_img{
            text-align: center;
            margin: 20px 0;
            img{
                height: 88px;
                object-fit: contain;
            }
        }
        .sec_item-sub{
            margin-bottom: 15px;
        }
    }
    &_item:nth-child(4){
        .function_img{
            img{
                padding: 14px 0;
            }
        }
    }
    &_item:nth-child(5){
        .function_img{
            img{
                padding: 5px 0;
            }
        }
    }
}


// 導入の流れ
.flow{
    background-color: $green-light;
    &_inner{
        position: relative;
        @include pc{
            &::after{
                @include bDeco;
                background-image: url(/assets/images/top/sec06_bg.png);
                width: 290px;
                height: 140px;
                left: -200px;
                bottom: 150px;
            }
        }
        @include sp{
            margin: 0 20px;
        }
    }
    .sec_ttl{
        text-align: left;
        @include sp{
            text-align: center;
            margin-bottom: 24px;
        }
    }
    &_inner{
        @include pc{
            display: flex;
            justify-content: space-between;
        }
    }
    &_head{
        position: relative;
        @include pc{
            width: 40%;
        }
    }
    &_img{
        @include sp{
            margin: 0 30px;
        }
        @include pc{
            position: absolute;
            top: 240px;
            left: -100px;
        }
        img{
            width: min(100%, 440px);
        }
    }
    &_list div:last-child{
        &::after{
            border: none;
        }
    }
    &_list{
        width: 60%;
        @include sp{
            width: 100%;
            margin-top: 20px;
        }
    }
    &_item{
        @include gShadow($backColor:#fff);
        padding: 30px 40px;
        margin-bottom: 40px;
        @include sp{
            padding: 30px;
            margin-bottom: 30px;
        }
        position: relative;
        .flow_ttl{
            @include pc{
                display: flex;
                align-items: center;
                .sec_item-main{
                    margin-left: 20px;
                }
            }
        }
        &::after{
            content: "";
            position: absolute;
            bottom: -30px;
            right: 50%;
            transform: translateX(calc(-50% + 30px));
            border: 15px solid;
            border-color: $green transparent transparent transparent;
        }
    }
}


// 料金体系
.price{
    padding: 140px 0 60px;
    text-align: center;
    @include sp{
        padding: 60px 0 0;
        margin: 0 20px;
    }
    &_inner{
        position: relative;
        &::before{
            @include bDeco;
            background-image: url(/assets/images/top/sec07_bg2.png);
            width: 139px;
            height: 85px;
            left: 0;
            bottom: 100px;
            @include sp{
                top: -100px;
                bottom: auto;
                width: 100px;
            }
        }
        @include pc{
            &::after{
                @include bDeco;
                background-image: url(/assets/images/top/price.png);
                width: 383px;
                height: 397px;
                right: -300px;
                bottom: -50px;
            }
        }
    }
    &_head{
        margin-bottom: 80px;
        position: relative;
        @include sp{
            margin-bottom: 60px;
        }
        .sec_ttl{
            margin-bottom: 20px;
            @include sp{
                margin-bottom: 24px;
            }
        }
        @include pc{
            &::after{
                @include bDeco;
                background-image: url(/assets/images/top/sec07_bg1.png);
                width: 120px;
                height: 128px;
                right: 0;
                top: -50px;
            }
        }
    }
    &_item{
        background-color: #fff;
        border-top: 3px solid $green;
        @include gShadow($backColor:#fff);
        padding: 0 54px 44px;
        @include sp{
            padding: 0 20px 24px;
        }
    }
    &_ttl-large{
        font-size: rem(20px, pc);
        @include gBack;
        width: 220px;
        margin: 0 auto -20px;
        transform: translateY(-20px);
        }
    &_ttl-small{
        margin-top: 30px;
        font-size: rem(18px, pc);
        background-color: $green-exlight;
        border-radius: 4px;
        @include gBold($fontColor:$txtColor);
    }
    .basic{
        margin: 0 150px 75px;
        @include sp{
            margin: 0 0 50px;
        }
        &_inner{
            display: grid;
            gap: 0 90px;
            @include pc{
                grid-template-columns:1fr 1fr;
            }
        }
        .initial{
            position: relative;
            @include sp{
                margin-bottom: 34px;
            }
            &::after{
                @include bDeco;
                background-image: url(/assets/images/top/plus.png);
                width: 28px;
                height: 28px;
                right: -58px;
                top: 110px;
                @include sp{
                    left: 50%;
                    top: 150px;
                    transform: translateX(-50%);
                }
            }
        }
    }
    &-large{
        font-size: rem(50px, pc);
        @include gBold;
    }
    &-small{
        font-size: rem(18px, pc);
        @include gBold($fontColor:$txtColor);
        margin-left:8px;
    }
    &-tiny{
        font-size: rem(14px, pc);
        @include gBold($fontColor:$txtColor);
        margin-left:8px;
    }
    .option{
        margin: 0 365px 50px;
        @include sp{
            margin: 0 0 24px;
        }
        &-large a{
            font-size: rem(30px, pc);
            @include gBold;
        }
    }
    .note{
        text-align: left;
        font-size: rem(14px, pc);
        @include pc{
            margin: 0 340px;
        }
    }
}


// よくある質問
.qa{
    &_inner>div{
        background-color: #fff;
        @include pc{
            padding: 80px 100px;
            margin-left: -100px;
            margin-right: -100px;
            border-radius: 15px;
        }
        @include sp{
            padding: 20px;
        }
        .sec_ttl{
            margin-bottom: 60px;
            @include sp{
                margin-bottom: 24px;
            }
        }
    }
    &_listInner{
        @include pc{
            margin: 0 100px 0;
        }
    }
    &_item{
        margin-bottom: 10px;
        &-q div,&-a div{
            position: relative;
            padding: 20px 44px 20px 100px;
            @include sp{
                padding: 20px 44px 20px 60px;
            }
            &::before{
                width: 34px;
                height: 34px;
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 44px;
                transform: translateY(-50%);
                text-align: center;
                @include sp{
                    padding-top: 3px;
                    left: 15px;
                }
            }
        }
        &-q {
            div{
                border-radius: 5px;
                cursor: pointer;
                background-color: $green-exlight;
                &::before{
                    content: "Q";
                    @include gBack;
                }
            }
        }
        &-q[data-acc-btn] {
            position: relative;
            &::before,&::after{
                content: "";
                width: 22px;
                height: 2px;
                background-color: $green;
                position: absolute;
                top: 50%;
                right: 40px;
                transform: translateY(-50%);
                z-index: 2;
                @include sp{
                    right: 15px;
                }
            }
            &::after{
                transition: transform .4s;
                transform: translateY(-50%) rotate(90deg);
            }
        }
        &[data-acc-root=open] &-q[data-acc-btn]::after {
            transform: translateY(-50%) rotate(0);
        }
        &-a {
            div{
                &::before{
                    content: "A";
                    @include gBack($backColor:#fff, $fontColor:$green);
                    border: 1px solid $green;
                }
            }
        }
    }
}


// お問い合わせ_2
.contact02{
    position: relative;
    padding-bottom: 160px;
    @include sp{
        padding-bottom: 60px;
    }
    &::after{
        content: "";
        width: max(90%, 1750px);
        height: 560px;
        background-color: $green;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 15px 0 0 15px;
        z-index: -1;
    }
    &_inner{
        position: relative;
        .sec_ttl{
            text-align: left;
            &-sub{
                color: #fff;
            }
        }
        >div{
            @include pc{
                display: grid;
                grid-template-columns: 470px 1fr;
            }
        }
        &::before{
            @include bDeco;
            background-image: url(/assets/images/top/sec09_bg.png);
            width: 127px;
            height: 80px;
            right: calc(50% - 127px);
            top: -20px;
            @include sp{
                width: 100px;
                right: 20px;
            }
        }
    }
    &_img{
        @include pc{
            position: absolute;
            bottom: -60px;
            right: calc(50% - 823px);
            img{
                width: 823px;
            }
        }
    }
}