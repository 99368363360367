// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
ボタンレイアウト

btns

Markup:
<div class="btns {$modifiers}">
	<ul class="btns_list">
		<li class="btns_item btns_item-3-12pc btns_item-5-12sp"><a href="" class="btn">PC: 3/12　SP: 5/12</a></li>
		<li class="btns_item btns_item-3-12pc btns_item-5-12sp"><a href="" class="btn">PC: 3/12　SP: 5/12</a></li>
		<li class="btns_item btns_item-3-12pc btns_item-12-12sp"><a href="" class="btn">PC: 3/12　SP: 12/12</a></li>
	</ul>
</div><!-- /.btns -->

.btns-left - 左寄せ
.btns-right - 右寄せ
.btns-hr - 横並び
.btns-hr.btns-left - 横並び&左寄せ
.btns-hr.btns-right - 横並び&右寄せ

Styleguide #{$guideNum}
*/
.btns {
	$this: &;
	margin: 20px 0 0;
	&_list {
		& > :first-child {
			margin-top: 0;
		}
	}
	&_item {
		margin: 20px auto 0;
		@for $i from 1 through 13 {
			&-#{$i}-12 {
				width: calc(99.9%/12 * #{$i});
			}
		}
		@for $i from 1 through 11 {
			&-#{$i}-10 {
				width: calc(99.9%/10 * #{$i});
			}
		}
	}
	&-left {
		#{$this}_item {
			margin-left: 0;
		}
	}
	&-right {
		#{$this}_item {
			margin-right: 0;
		}
	}

	&-hr {
		& > :first-child {
		}
		#{$this}_list {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: -20px 0 0 -20px;
		}
		#{$this}_item {
			margin: 20px 0 0 20px;
			@for $i from 1 through 13 {
				&-#{$i}-12 {
					width: calc(99.9%/12 * #{$i} - 20px);
				}
			}
			@for $i from 1 through 11 {
				&-#{$i}-10 {
					width: calc(99.9%/10 * #{$i} - 20px);
				}
			}
		}
	}
	&-hr.btns-left {
		#{$this}_list {
			justify-content: flex-start;
		}
	}
	&-hr.btns-right {
		#{$this}_list {
			justify-content: flex-end;
		}
	}
	&-hr.btns-spaceBetween {
		#{$this}_list {
			justify-content: space-between;
		}
	}
}

@media screen and (min-width: $breakpoint-sp + 1){
	.btns {
		$this: &;
		&_item {
			margin: 20px auto 0;
			@for $i from 1 through 13 {
				&-#{$i}-12pc {
					width: calc(99.9%/12 * #{$i});
				}
			}
			@for $i from 1 through 11 {
				&-#{$i}-10pc {
					width: calc(99.9%/10 * #{$i});
				}
			}
		}
		&-hr {
			#{$this}_item {
				@for $i from 1 through 13 {
					&-#{$i}-12pc {
						width: calc(99.9%/12 * #{$i} - 20px);
					}
				}
				@for $i from 1 through 11 {
					&-#{$i}-10pc {
						width: calc(99.9%/10 * #{$i} - 20px);
					}
				}
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.btns {
		$this: &;
		&_list {}
		&_item {
			margin: 20px auto 0;
			@for $i from 1 through 13 {
				&-#{$i}-12sp {
					width: calc(99.9%/12 * #{$i});
				}
			}
			@for $i from 1 through 11 {
				&-#{$i}-10sp {
					width: calc(99.9%/10 * #{$i});
				}
			}
		}
		&-hr {
			#{$this}_list {
				margin: -10px 0 0 -10px;
			}
			#{$this}_item {
				margin: 10px 0 0 10px;
				@for $i from 1 through 13 {
					&-#{$i}-12sp {
						width: calc(99.9%/12 * #{$i} - 10px);
					}
				}
				@for $i from 1 through 11 {
					&-#{$i}-10sp {
						width: calc(99.9%/10 * #{$i} - 10px);
					}
				}
			}
		}
		&_item {
		}
	}
}