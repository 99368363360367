// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';



/*
youtube

スマホでyoutubeのレスポンシブ化（16:9の比率）

Markup:
<div class="youtube"><iframe width="560" height="315" src="https://www.youtube.com/embed/Duq5QDp6KqE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>

Styleguide #{$guideNum}
*/

@media screen and (max-width: $breakpoint-sp){
	.youtube {
		position: relative;
		padding-top: calc(100% * 360 / 640);
		margin-top: 20px;
		& > iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

