// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
layout

大枠のレイアウト

Markup:
{$modifiers}

Styleguide #{$guideNum}
*/

.wrapper {
	$this: &;
	min-width: calc($page-width + 40px);
	min-height: 100%;
	overflow-x: hidden;
	& > :first-child {
		margin-top: 0;
	}
}



//pxをvwに変換する
@function _get_vw($size, $viewport: 375) {
	$rate: calc(100 / $viewport);
	@return $rate * $size * 1vw;
  }
  
  //画像用のmixin
@mixin img-vw($img_size, $viewsize: 375) {
	width: $img_size * 1px;
	width: _get_vw($img_size, $viewsize);
  }



.contents {
	$this: &;
	display: flex;
	display: -webkit-flex;
	justify-content: space-between;
	width: $page-width;
	margin: 0 auto;
	&_img{
		img{
			max-width: 683px;
		}
	}
	& > :first-child {
		margin-top: 0;
	}
	&_main {
		flex-grow: 0;
		flex-shrink: 0;
		width: $main-width;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_side {
		flex-grow: 0;
		flex-shrink: 0;
		width: $side-width;
		& > :first-child {
			margin-top: 0;
		}
	}
	&-noSide {
		display: block;
		#{$this}_main {
			width: auto;
		}
		#{$this}_side {
			display: none !important;
		}
	}
}


.section {
	$this: &;
	& > :first-child {
		margin-top: 0;
	}
	&_inner {
		max-width: $page-width;
		margin: 0 auto;
		& > :first-child {
			margin-top: 0;
		}
	}
}

.section02{
	padding: 140px 0;
	@include sp{
		padding: 60px 0;
	}
}

.sideSection {
	$this: &;
	& > :first-child {
		margin-top: 0;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.wrapper {
		$this: &;
		min-width: 100%;
	}

	.contents {
		$this: &;
		display: block;
		width: auto;
		&_main {
			width: auto;
			& > :first-child {
				margin-top: 0;
			}
		}
		&_side {
			width: auto;
			& > :first-child {
				margin-top: 0;
			}
		}
		&-noSide {
		}
	}

	.section {
		$this: &;
		&_inner {
			width: auto;
		}
	}

	.sideSection {
		$this: &;
	}
}

