// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
overlay

オーバーレイ

Markup:
<a href="javascript: void(0);" class="overlay"></a>

Styleguide #{$guideNum}
*/
.overlay {
	$this: &;
	background: $overlayBg;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	transition: .4s opacity;
	z-index: 1000;
	opacity: 0;
	&-enter-active {
		display: block;
	}
	&-enter {
		display: block;
		opacity: 0;
		&:hover {
			opacity: 0;
		}
	}
	&-enter-to {
		display: block;
		opacity: 1;
		&:hover {
			opacity: 1;
		}
	}

	&-leave-active {
		display: block;
	}
	&-leave {
		display: block;
		opacity: 1;
		&:hover {
			opacity: 1;
		}
	}
	&-leave-to {
		display: block;
		opacity: 0;
		&:hover {
			opacity: 0;
		}
	}
}

