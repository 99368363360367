.footer{
    background-color: #fff;
    &_inner{
        max-width: $page-width;
		margin: 0 auto;
        & > :first-child {
            margin-top: 0;
		}
        &>div{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: 76px 0 64px;
            @include sp{
                display: block;
                padding: 60px 0 80px;
            }
            @include sp{
                margin: 0 20px;
            }
        }
    }
    &_logo{
        width: 200px;
        margin-bottom: 34px;
        @include sp{
            max-width: 150px;
            margin-bottom: 20px;
        }
    }
    &_privacy{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-bottom: 30px;
        @include sp{
            display: block;
            margin: 20px 0;
        }
    }
    &_privacy-link{
        margin-right: 30px;
        span{
            font-size: 0.875rem;
            display: inline-block;
            display: flex;
            align-items: center;
            &::after{
                content: "";
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                margin-left: 4px;
                background-image: url("../../assets/images/top/tabs.svg");
                width: 13px;
                height: 13px;
            }
        }
        @include sp{
            a{
                display: inline-block;
            }
        }
    }
    &_mark{
        @include sp{
            margin: 10px 0;
        }
    }
	&_copyright {
        font-size: rem(14px, pc);
        @include sp{
            text-align: center;
        }
	}
}