// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

.editorTmplBlocks03 {
	$this: &;
	margin: 20px 0 0;
	&_list {
		display: flex;
		display: -webkit-flex;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		flex-direction: row;
		margin: -20px 0 0 -20px;
	}
	&_item {
		margin: 20px 0 0 20px;
		width: calc(99.9%/3 - 20px);
		& > a {
			display: flex;
			display: -webkit-flex;
			align-items: center;
			height: 100%;
			padding: 10px;
			border-radius: 5px;
			border: 1px solid #ddd;
			text-decoration: none;
			color: inherit;
		}
	}
	&_img {
		flex-grow: 0;
		flex-shrink: 0;
		margin: 0 10px 0 0;
		width: 26%;
		padding-top: 26%;
		text-align: center;
		position: relative;
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 100%;
		}
	}
	&_body {
		flex-grow: 1;
		flex-shrink: 1;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_title {
		font-weight: bold;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.editorTmplBlocks03 {
		$this: &;
		&_list {
			margin: 0;
			display: block;
			& > :first-child {
				margin-top: 0;
			}
		}
		&_item {
			width: auto;
			margin: 20px 0 0;
			& > a {
				align-items: flex-start;
			}
		}
		&_img {
			margin-right: 10px;
			width: 30%;
			padding-top: 30%;
		}
		&_body {
			align-self: center;
		}
		&_title {
		}
	}
}