// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
toTop

toTop

Markup:
<div class="toTop"><a href="#"></a></div>

Styleguide #{$guideNum}
*/
.toTop{
    width: fit-content;
    position: fixed;
    z-index: 5;
    right: 20px;
    bottom: 20px;
    a{
        @include gBack;
        width: 50px;
        height: 50px;
        display: block;
        &::before{
            width: 16px;
            height: 16px;
            @include bDeco;
            background-image: url(/assets/images/top/btn_arrow01.png);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transform: rotate(-90deg);
        }
    }
}